<template>
    <modal ref="subirAvatar" titulo="Subir avatar" @guardar="crear_avatar">
        <div class="row mx-0 justify-center my-2">
            <div class="col-auto text-center">
                <slim-cropper ref="cropChat" :options="slimOptions" class="border rounded-circle" style="height:120px;width:120px;background:#F8F9FF;">
                    <div slot="label" class="">
                        <img src="/img/modales/camera.svg" alt="" />
                    </div>
                </slim-cropper>
                <small>Mínimo 80x80 px</small>
            </div>
        </div>
    </modal>
</template>

<script>
import Aws from '~/services/aws'
import Avatar from "~/services/configurar/admin/grupoAvatares"

export default {
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label: 'Subir',
                // size: { width: 250, height: 250 },
                // minSize: { width: 100, height: 100 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            id: null,
            id_padre: null,
            imagen: null,
        }
    },
    methods:{
        toggle(datos){
            this.$refs.subirAvatar.toggle()
            this.id = datos.id
            this.id_padre = datos.id_padre
            this.imagen = datos.imagen
            if (datos.imagen != null){
                this.$refs.cropChat.set_image(`${datos.imagen_firmada}`)
            } else {
                this.$refs.cropChat.instanciaCrop.remove()
            }
        },
        async crear_avatar(){
            try {
                const archivo = this.$refs.cropChat.instanciaCrop.dataBase64.output
                if(!archivo){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                let model = {
                    id: this.id,
                    id_padre: this.id_padre,
                    imagen: archivo.image
                }

                const {data} = await Avatar.crear_avatar(model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.$emit('actualizar')
                this.$refs.subirAvatar.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="css" scoped>
</style>
